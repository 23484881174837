.global,
.global * {
  box-sizing: border-box;
}

.global code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.global::-webkit-scrollbar
.global *::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
}

.global::-webkit-scrollbar-track
.global *::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 12px 0;
}

.global::-webkit-scrollbar-thumb
.global *::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #99b3c8;
  outline: 1px solid #99b3c8;
}
