/* Important! This file overwrites styles on sdir.no use with caution. New projects should avoid introducing global styles
if they are to be embedded into sdir.no.  */

html {
  /* This sets the the scale value of REM. Should be 10px since that is sdir.no's default. Changing this now
  will introduce alot of work to change all the rem references in the project. Does not seem to be a problem
  in sdir.no/msk/ since all sizes seems to be inherited from body or exact pixelvalues */
  font-size: 8px;
  /* Does not seem to affect anything */
  height: 0 !important;
}

body {
  /* Dirty fix, but does not seem to effect sdir.no/msk/# */
  background: #f7f7f7 !important;
  margin: 0;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 12px 0;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #99b3c8;
  outline: 1px solid #99b3c8;
}

.react-datepicker__aria-live {
  display: none;
}
